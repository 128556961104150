<template>
  <section>
    <v-card class="custom-border border pa-2" v-if="!loading">
      <section class="d-flex flex-row justify-space-between mx-3 mt-3">
        <div class="poppins fw600 f18 secondary-1--text">My Courses</div>
        <div class="d-flex justify-end" v-if="courses.length>0">
          <v-btn 
            text 
            small
            @click="gallery =  true"
            class="hidden-sm-and-down"
          >
            <v-icon size="22" :class="gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-view-gallery-outline
            </v-icon>
          </v-btn>
          <v-btn 
            text 
            small
            @click="gallery = false"
            class="hidden-sm-and-down"
          >
            <v-icon size="22" :class="!gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </div>
      </section>
      
        <!-- <v-row dense>
          <v-col cols="12" :md="gallery && 3" :sm="gallery && 4"
            v-for="(item,i) in courses" 
              :key="i" >
              <CourseCard 
                :i="i" 
                :data="item" 
                :gallery="gallery"
                class="ma-1"
                :progress="getProgress(item)"
              />
          </v-col>
        </v-row> -->
        <v-sheet :height="!gallery ? 'auto' : 'auto'" :max-height="gallery ? '80vh' : '60vh'" v-if="courses.length>0" class="d-flex overflow-y-auto px-1 my-4 mx-4 ml-4" :class="[gallery ? 'flex-wrap' : 'flex-column']">
          <CardGallery 
            v-for="(item,i) in courses"
            v-if="gallery"
            :key="i"
            :data="item"
            :progress="getProgress(item)"
            class="ma-1"
          />
          <CardList 
            v-for="(item,i) in courses"
            v-if="!gallery"
            :key="i"
            :data="item"
            :progress="getProgress(item)"
            class="my-1"
          />
        </v-sheet>
      
      <!-- <div v-if="courses.length>0" :class="gallery && 'coursebox'">
        <CourseCard 
          v-for="item in courses" 
          :key="item.id" 
          :data="item" 
          :progress="getProgress(item)"
          :gallery="gallery"/>
      </div> -->
      <v-sheet
        v-else
        color="transparent"
        class="d-flex flex-column align-center justify-center pb-10"
        height="400"
      >
        <div class="">
          <v-img 
            :max-width="$vuetify.breakpoint.mobile?200:300" 
            :src="require('../../assets/default/empty_drafts.png')"/>
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5">Nothing in Courses</h3>
      </v-sheet>
      <v-card-text v-if="other_courses.length > 0" class="mt-5">
        <div class="poppins fw600 f18 secondary-1--text"  v-if="other_courses.length > 0">Browse for more courses</div>
        <v-slide-group
          show-arrows
          class="mx-2"
          v-if="gallery"
        >
            <v-slide-item v-for="course in other_courses" :key="course.id">
                <GalleryCards class="ma-1" :course="course" />
            </v-slide-item>
        </v-slide-group>
        <section v-if="!gallery" class="d-flex flex-column justify-center'">
            <ListCards v-for="course in other_courses" :key="course.id" :course="course" class="my-2"/>
        </section>
      </v-card-text>
    </v-card>
    <circular v-else/>
  </section>
</template>


<style>
.v-slide-group__prev, .v-slide-group__next {
  min-width: auto !important;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import CardGallery from '../../components/user/course/CardGallery.vue';
import CardList from '../../components/user/course/CardList.vue';
import CourseCard from '../../components/user/course/Card.vue'
import GalleryCards from '../../components/user/course/GalleryCard.vue'
import ListCards from '../../components/user/course/ListCard.vue'

export default {
  components: {
    CourseCard,
    CardGallery,
    CardList,
    GalleryCards,
    ListCards
  },
  data: () => ({
    loading: false,
    gallery: true,
  }),
  computed: {
    ...mapState({
      other_courses: (state) => state.other_courses,
      tenant: (state) => state.tenant,
    }),
    ...mapState('usr', {
      courses: (state) => state.courses,
      cart: (state) => state.cart
    }),
  },

  mounted() {
    window.addEventListener('resize', this.onResize, {passive: true})
    this.onResize()
  },

  methods: {
    ...mapActions('usr', ['getCoursesAction']),

    getProgress(item){
      let progress = Math.round(((item.total_done_assessments + item.total_done_topics + item.total_done_sub_topics) / (item.assessments_count + item.topics_count + item.sub_topics_count)) * 100)
      return progress ? progress : 0
    },

    onResize(){
      if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
        this.gallery = false
      }
    }
  },
}
</script>